import { render, staticRenderFns } from "./psychologist.vue?vue&type=template&id=0a6778d5&scoped=true&"
import script from "./psychologist.vue?vue&type=script&lang=js&"
export * from "./psychologist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6778d5",
  null
  
)

export default component.exports