<template>
  <patientPsychologist></patientPsychologist>
</template>

<script>
const patientPsychologist = () =>
  import("../../../components/dashboard/patientDashboard/patientPsychologist");
export default {
  name: "psychologist",
  components: { patientPsychologist },
};
</script>

<style scoped></style>
